import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Layout } from '../components/layout'
import { Footer } from '../components/footer'
import { Teaser } from '../components/teaser'

import { Grid, Row, Col } from '../components/common/grid'
import { H3 } from '../components/common/heading'

const Headline = styled(Link)`
  display: block;
  color: ${props => props.color};
`

const More = styled(Link)`
  display: block;
  margin-top: 20px;
`

const title = 'Chimpanzee Natural Nutrition'
const description =
  'Natürliche Energie- und Proteinriegel, Gunpowder Drinks, Shakes und Gels'
const keywords = ''

const ProductsPage = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="home" />

    <Grid>
      <Row centered>
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/energieriegel">
              <H3>Energieriegel</H3>
            </Headline>
            <p>
              Die Chimpanzee Energieriegel sind eine wunderbare, natürliche
              Energiequelle für jede Aktivität. Sie enthalten eine Mischung aus
              komplexen Kohlenhydraten, sind reich an Ballaststoffen und Eiweiß
              und bilden daher einen großartigen Energielieferanten.
            </p>
            <More to="produkte/energieriegel">Mehr über Energieriegel</More>
          </div>
        </Col>
        <Col xs={0} md={1} />
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/proteinriegel">
              <H3>Proteinriegel</H3>
            </Headline>
            <p>
              Die BIO zertifizierten Proteinriegel von Chimpanzee sind vegan,
              glutenfrei und ohne Geschmacksverstärker. Sie enthalten
              pflanzliches Eiweiß aus Vollkornreis und Mandelpaste und schmecken
              einfach fantastisch.{' '}
            </p>
            <More to="produkte/proteinriegel">Mehr über Proteinriegel</More>
          </div>
        </Col>
      </Row>

      <Row centered>
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/gunpowder-drinks">
              <H3>Gunpowder Drinks</H3>
            </Headline>
            <p>
              Chimpanzee Gunpowder Energy Drinks sind isotonische
              Energie-Getränke ohne künstliche Zusatzstoffe, reich an Natrium,
              Kalium, Magnesium, Kalzium und Kohlenhydraten. Sie sind
              ausgezeichnete Energielieferanten!
            </p>
            <More to="produkte/gunpowder-drinks">
              Mehr über Gunpowder Drinks
            </More>
          </div>
        </Col>
        <Col xs={0} md={1} />
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/quickmix-shakes">
              <H3>QuickMix Shakes</H3>
            </Headline>
            <p>
              Hergestellt auf rein pflanzlicher Basis sind die Chimpanzee Energy
              und Protein Shakes auch für Veganer geeignet. Mit Wasser, Milch
              oder Joghurt, warm oder kalt, als Brei oder Getränk — vielfältige
              Möglichkeiten für jede Situation.
            </p>
            <More to="produkte/quickmix-shakes">Mehr über QuickMix Shakes</More>
          </div>
        </Col>
      </Row>

      <Row centered>
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/yippee-bars">
              <H3>Yippee Bars</H3>
            </Headline>
            <p>
              Energieriegel mit einem hohen Fruchtanteil speziell für Kinder
              entwickelt.
            </p>
            <More to="produkte/yippee-bars">Mehr über Yippee Bars</More>
          </div>
        </Col>
        <Col xs={0} md={1} />
        <Col xs={12} md={4} bottom={100}>
          <div>
            <Headline to="produkte/energy-chews">
              <H3>Energy Chews</H3>
            </Headline>
            <p>
              Vegane Frucht-Gummibärchen mit komplexen Kohlehydraten und hohem
              Fruchtanteil.
            </p>
            <More to="produkte/energy-chews">Mehr über Energy Chews</More>
          </div>
        </Col>
      </Row>
    </Grid>

    <Footer bottom={0} />
  </Layout>
)

export default ProductsPage
